import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { useApi } from "../../../../hooks/useFetch";

const EditUserDataModal = ({ setShow, data, reloadPage }) => {
  const { t } = useTranslation();
  const [firstname, setFirstname] = useState(data?.firstName);
  const [lastname, setLastname] = useState(data?.lastName);
  const { handleSubmit, register, errors } = useForm();
  var formData = new FormData();

  const [putUserProfile] = useApi();

  const onSubmit = (values) => {
    formData.append("first_name", firstname);
    formData.append("last_name", lastname);
    formData.append("images", values?.picture[0]);
    putUserProfile("put", `en/api/auth_user/profile/personal/`, {}, formData);
    reloadPage();
    setShow();
  };

  return (
    <form className="p-2" onSubmit={handleSubmit(onSubmit)}>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between m-2">
          <h3>{t("Edit your data")}</h3>
          <p
            onClick={() => setShow()}
            className="my-0"
            style={{ fontSize: 30, cursor: "pointer" }}
          >
            ×
          </p>
        </div>
        <p className="my-0" style={{ fontSize: 18 }}>
          {t("first name")}
        </p>
        <input
          name="firstname"
          ref={register({
            required: "Required",
          })}
          className="border-top-0 border-right-0 border-left-0 border-bottom my-3"
          placeholder={t("firstName")}
          value={firstname}
          onChange={(e) => setFirstname(e.target.value)}
        />
        <p style={{ color: "red", fontSize: 12 }}>
          {errors.firstname && errors.firstname.message}
        </p>
        <p className="my-0" style={{ fontSize: 18 }}>
          {t("last name")}
        </p>
        <input
          name="lastname"
          ref={register({
            required: "Required",
          })}
          className="border-top-0 border-right-0 border-left-0 border-bottom my-3"
          placeholder={t("lastName")}
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
        />
        <p style={{ color: "red", fontSize: 12 }}>
          {errors.lastname && errors.lastname.message}
        </p>
        <input ref={register} type="file" name="picture" />
        <button className="btn btn-primary rounded-0 my-2">Submit</button>
      </div>
    </form>
  );
};

export default EditUserDataModal;
