import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import ReactLoading from "react-loading";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Image, Button, Modal } from "react-bootstrap";
import { BsFillPersonFill, BsFillChatDotsFill } from "react-icons/bs";
import {
  AiOutlineMail,
  AiOutlinePhone,
  AiOutlineStar,
  AiFillStar,
} from "react-icons/ai";
import { GoLocation } from "react-icons/go";
import Rating from "react-rating";
import SweetAlert from "react-bootstrap-sweetalert";

import Theme from "../../styles/theme";
import { Context as mainContext } from "../../Context/mainContext";
import Cover from "../../assets/images/cover.png";
import FullConfirmed from "../../assets/images/fullConfirmed.png";
import BadgeConfirmed from "../../assets/images/badge_confirmed.png";
import BadgeIdentityTypes from "../../assets/images/badge_identity_types.png";
import Card from "./component/Card";
import FollowingModal from "./component/FollowingModal";
import FollowersModal from "./component/FollowersModal";
import { baseUrl } from "../../utilts";

const ProfileToVisit = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { changeReciverId, changeUserName } = useContext(mainContext);
  const [personData, setPersonData] = useState({});
  const [followingData, setFollowingData] = useState({});
  const [followersData, setFollowersData] = useState({});
  const [itemData, setItemsData] = useState([]);
  const [addressesData, setAddressesData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [followAlert, setFollowAlert] = useState(false);
  const [followErrorAlert, setFollowErrorAlert] = useState(false);
  const [showFollowingModal, setShowFollowingModal] = useState(false);
  const [showFollowersModal, setShowFollowersModal] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const userId = location.state;
  const token = localStorage.getItem("accessToken");

  const ChangeFollowState = async () => {
    token != null
      ? await axios({
          method: "post",
          url: `${baseUrl}en/api/tweets/followunfollow/${userId?._id}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            setFollowAlert(true);
            setFollowErrorAlert(false);
          })
          .catch((error) => {
            setFollowAlert(false);
            setFollowErrorAlert(true);
          })
      : navigate({
          pathname: "/login",
          state: {
            route: "/",
          },
        });
  };
  const closeFollowAlert = () => {
    setFollowAlert(false);
    setReloadPage(!reloadPage);
  };
  const closeFollowErrorAlert = () => {
    setFollowErrorAlert(false);
  };
  const navigateToContact = () => {
    changeReciverId(personData?.id);
    changeUserName(personData?.first_name + " " + personData?.last_name);
    navigate({
      pathname: `/Contact/${personData?.username}`,
      state: {
        _id: personData?.id,
      },
    });
  };

  const handleFollwingModal = () => {
    setShowFollowingModal(false);
  };

  const handleFollwersModal = () => {
    setShowFollowersModal(false);
  };

  const followPerson = t("follow");
  const unfollowPerson = t("unfollow");
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      token != null
        ? await axios({
            method: "get",
            url: `${baseUrl}en/api/auth_user/profile/any/${userId?._id}`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
            .then((res) => {
              const response = res.data.context;
              setPersonData(response);
            })
            .catch((error) => {
              if (error?.response.status === 401) {
                navigate("/login");
              }
              const errorToSend = error?.response?.statusText?.replace(
                / /g,
                "_"
              );
              (async () => {
                try {
                  await axios({
                    method: "post",
                    url: `${baseUrl}en/api/logs/web/${errorToSend}`,
                  })
                    .then((res) => {
                      console.log(res);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                } catch (error) {
                  console.log(error);
                }
              })();
            })
        : await axios({
            method: "get",
            url: `${baseUrl}en/api/auth_user/profile/any/${userId?._id}`,
          })
            .then((res) => {
              const response = res.data.context;
              setPersonData(response);
            })
            .catch((error) => {
              if (error?.response.status === 401) {
                navigate("/login");
              }
              const errorToSend = error?.response?.statusText.replace(
                / /g,
                "_"
              );
              (async () => {
                try {
                  await axios({
                    method: "post",
                    url: `${baseUrl}en/api/logs/web/${errorToSend}`,
                  })
                    .then((res) => {
                      console.log(res);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                } catch (error) {
                  console.log(error);
                }
              })();
            });
      await axios({
        method: "get",
        url: `${baseUrl}en/api/items/user/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          const response = res.data.context;
          setItemsData(response);
        })
        .catch((error) => {
          if (error?.response.status === 401) {
            navigate("/login");
          }
          const errorToSend = error?.response?.statusText?.replace(/ /g, "_");
          (async () => {
            try {
              await axios({
                method: "post",
                url: `${baseUrl}en/api/logs/web/${errorToSend}`,
              })
                .then((res) => {
                  console.log(res);
                })
                .catch((error) => {
                  console.log(error);
                });
            } catch (error) {
              console.log(error);
            }
          })();
        });
      await axios({
        method: "get",
        url: `${baseUrl}en/api/addresses/`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          const response = res.data?.context;
          setAddressesData(response);
        })
        .catch((error) => {
          console.log({ errorResponse: error.response });
        });
      await axios({
        method: "get",
        url: `${baseUrl}en/api/tweets/followers/any/${userId?._id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          const response = res.data;
          setFollowersData(response);
        })
        .catch((error) => {
          console.log({ errorResponse: error.response });
        });
      await axios({
        method: "get",
        url: `${baseUrl}en/api/tweets/following/any/${userId?._id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          const response = res.data;
          setFollowingData(response);
        })
        .catch((error) => {
          console.log({ errorResponse: error.response });
        });
      setIsLoading(false);
    })();
  }, [reloadPage, userId?._id]);

  if (isLoading === true) {
    return (
      <div
        style={{ height: "100vh" }}
        className="d-flex justify-content-center align-items-center"
      >
        <ReactLoading
          type="bubbles"
          height={150}
          width={150}
          color={Theme.colors.darkGrey}
        />
      </div>
    );
  }
  return (
    <div className="container-fluid h-100 mb-5">
      <div className="col-12 d-flex align-items-center">
        <h1>
          {personData?.first_name} {personData?.last_name}
        </h1>
      </div>
      <div className="col-12">
        <Image
          className="rounded"
          style={{ height: 200, width: "100%", objectFit: "cover" }}
          src={Cover}
        />
      </div>
      <div
        style={{ top: -100 }}
        className="col-12 d-flex flex-column flex-md-row align-items-center align-items-md-end"
      >
        <div
          className="card ml-0 ml-md-5 d-flex justify-content-center align-items-center"
          style={{
            height: 200,
            width: 200,
            backgroundColor: Theme.colors.white,
          }}
        >
          {personData?.image != null ? (
            <Image
              className="rounded"
              style={{ objectFit: "cover", height: "100%", width: 200 }}
              src={personData?.image}
            />
          ) : (
            <BsFillPersonFill
              className="rounded-circle p-1"
              style={{
                backgroundColor: Theme.colors.darkGrey,
                color: Theme.colors.white,
              }}
              size={56}
            />
          )}
          {personData.badge_confirmed == true && (
            <Image
              style={{ position: "absolute", right: 0, bottom: 0 }}
              height="35"
              width="40"
              src={BadgeConfirmed}
            />
          )}
          {personData.badge_identity_types == true &&
          personData.badge_confirmed == true ? (
            <Image
              style={{ position: "absolute", right: 0, bottom: 0 }}
              height="35"
              width="40"
              src={BadgeIdentityTypes}
            />
          ) : (
            personData.badge_identity_types == true && (
              <Image
                style={{ position: "absolute", right: 0, bottom: 0 }}
                height="35"
                width="40"
                src={FullConfirmed}
              />
            )
          )}
        </div>
        <div className="d-flex flex-column flex-md-row w-100 mx-2">
          <div className="d-flex flex-column flex-md-row justify-content-center align-items-center w-100">
            <div className="m-3">
              <p className="my-0">
                {t("name")}: {personData?.first_name} {personData?.last_name}
              </p>
              <p className="my-0">
                {t("username")}: @{personData?.username}
              </p>
            </div>
            <div className="border-left my-3 mx-0 mx-md-3 my-md-3">
              <p className="mx-1 my-0 p-0">
                <span>
                  <AiOutlineMail className="mx-1" size={15} />
                </span>
                {t("email")}: {personData?.email}
              </p>
              <p className="mx-1 my-0 p-0">
                <span>
                  <AiOutlinePhone className="mx-1" size={15} />
                </span>
                {t("phone")}: {personData?.phone}
              </p>
            </div>
            <div className="border-left my-3 mx-0 mx-md-3 my-md-3">
              {addressesData.length > 0 && (
                <p className="mx-1 my-0 p-0 d-flex justify-content-center align-items-center">
                  <span className="d-flex">
                    <GoLocation className="mx-1" size={15} />
                    <p
                      className="my-0"
                      style={{
                        width: "40%",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        fontSize: 12,
                      }}
                    >{`${addressesData[0]?.area}, ${addressesData[0]?.street}`}</p>
                  </span>
                </p>
              )}
              <p className="mx-1 my-0 p-0">
                <span>
                  <AiOutlineStar className="mx-1" size={15} />
                </span>
                {t("rating")}:{" "}
                <span>
                  <Rating
                    readonly={true}
                    placeholderRating={
                      personData?.rating == "None" ? 0 : personData?.rating
                    }
                    emptySymbol={
                      <AiFillStar size={15} color={Theme.colors.darkGrey} />
                    }
                    placeholderSymbol={
                      <AiFillStar size={15} color={Theme.colors.yellow} />
                    }
                    fullSymbol={
                      <AiFillStar size={15} color={Theme.colors.yellow} />
                    }
                  />
                </span>
              </p>
            </div>
            <div className="border-left my-3 mx-0 mx-md-3 my-md-3">
              <p
                onClick={() => setShowFollowingModal(true)}
                style={{ cursor: "pointer" }}
                className="mx-1 my-0 p-0"
              >
                {t("following")}: {personData?.following_count}
              </p>
              <p
                onClick={() => setShowFollowersModal(true)}
                style={{ cursor: "pointer" }}
                className="mx-1 my-0 p-0"
              >
                {t("followers")}: {personData?.followers_count}
              </p>
            </div>
            <div className="d-flex flex-column border-left my-3 mx-0 mx-md-3 my-md-3">
              <Button
                onClick={() => ChangeFollowState()}
                variant="primary rounded-0 px-5 py-1 m-1"
              >
                {personData?.is_following == true
                  ? unfollowPerson
                  : followPerson}
              </Button>
              <Button
                disabled={false}
                onClick={() => navigateToContact()}
                variant="light rounded-0 px-5 py-1 m-1"
              >
                {t("Contact with seller")}
                <span className="mx-2">
                  <BsFillChatDotsFill color={Theme.colors.blue} size={20} />
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 d-flex justify-content-between my-2">
        <h4 className="my-0">{t("Checkout Items")}</h4>
      </div>
      <div className="col-12 d-flex flex-wrap justify-content-center p-0">
        {itemData.length > 0 ? (
          itemData?.map((item, index) => {
            return <Card key={index} index={index} item={item} />;
          })
        ) : (
          <div className="m-auto">
            <h6>{t("there is no item added")}</h6>
          </div>
        )}
      </div>
      <Modal show={showFollowingModal} onHide={() => handleFollwingModal()}>
        <FollowingModal
          data={followingData}
          CloseModal={() => handleFollwingModal()}
        />
      </Modal>
      <Modal show={showFollowersModal} onHide={() => handleFollwersModal()}>
        <FollowersModal
          data={followersData}
          CloseModal={() => handleFollwingModal()}
        />
      </Modal>
      {followAlert && (
        <SweetAlert
          type="success"
          title={t("You are following him now")}
          onConfirm={() => closeFollowAlert()}
          focusCancelBtn={true}
        />
      )}
      {followErrorAlert && (
        <SweetAlert
          type="error"
          title={t("we have a problem please try again later")}
          onConfirm={() => closeFollowErrorAlert()}
          focusCancelBtn={true}
        />
      )}
    </div>
  );
};

export default ProfileToVisit;
