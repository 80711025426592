import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Button, Dropdown, FormControl } from "react-bootstrap";
import axios from "axios";

import Theme from "../../../../styles/theme";
import { baseUrl } from "../../../../utilts";

const AddAddressModal = ({
  setAddAdressesModal,
  setItemAdded,
  setErrorAddItems,
}) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [area, setArea] = useState("");
  const [block, setBlock] = useState("");
  const [street, setStreet] = useState("");
  const [building, setBuilding] = useState("");
  const [flat, setFlat] = useState("");
  const [paciNumber, setPaciNumber] = useState("");
  const [countriesData, setCountriesData] = useState([]);
  const [countrySelected, setCountrySelected] = useState({
    name: t("all"),
    id: 0,
  });

  useEffect(() => {
    (async () => {
      try {
        await axios({
          method: "get",
          url: `${baseUrl}en/api/countries/`,
        })
          .then((res) => {
            setCountriesData(res.data?.context);
          })
          .catch((error) => {
            const errorToSend = error?.response?.statusText?.replace(
              / /g,
              "_"
            )(async () => {
              await axios({
                method: "post",
                url: `${baseUrl}en/api/logs/web/${errorToSend}`,
              })
                .then((res) => {
                  console.log(res);
                })
                .catch((error) => {
                  console.log(error);
                });
            })();
          });
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  var formData = new FormData();
  const { handleSubmit } = useForm();

  const onSubmit = async (values) => {
    formData.append("name", name);
    formData.append("country", countrySelected?.id);
    formData.append("phone", phone);
    formData.append("area", area);
    formData.append("block", block);
    formData.append("street", street);
    formData.append("building", building);
    formData.append("flat", flat);
    formData.append("paci_number", paciNumber);
    formData.append("publish", true);
    SaveNewAddress();
  };

  const SaveNewAddress = () => {
    const token = localStorage.getItem("accessToken");
    (async () => {
      try {
        await axios({
          method: "post",
          url: `${baseUrl}en/api/addresses/`,
          data: formData,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            setItemAdded();
          })
          .catch((error) => {
            setErrorAddItems();
            if (error.response) {
              console.log("error?.response", error?.response);
            } else if (error.request) {
              console.log("error?.message", error?.message);
            } else if (error.message) {
              console.log("error?.request", error?.request);
            }
          });
      } catch (error) {
        console.log(error);
      }
    })();
    setAddAdressesModal(false);
  };
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <p
      className="d-flex justify-content-between align-items-center border-bottom"
      style={{ cursor: "pointer", color: Theme.colors.darkGrey }}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className="ml-2" style={{ fontSize: 12 }}>
        &#x25bc;
      </span>
    </p>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder={t("Type to filter Countries")}
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );
  return (
    <form className="p-3" onSubmit={handleSubmit(onSubmit)}>
      <div className="p-2">
        <div className="d-flex justify-content-between m-2 border-bottom">
          <h3>{t("Add new address")}</h3>
          <p
            className="my-0"
            onClick={() => setAddAdressesModal()}
            style={{ fontSize: 30, cursor: "pointer" }}
          >
            ×
          </p>
        </div>
        <div className="w-100">
          <p className="my-0" style={{ fontSize: 18 }}>
            {t("name")}
          </p>
          <input
            name="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
            placeholder={t("name")}
            style={{ fontSize: 14, color: Theme.colors.darkGrey }}
          />
        </div>
        <div className="w-100">
          <p className="my-0" style={{ fontSize: 18 }}>
            {t("Select country")}
          </p>
          <Dropdown className="w-100 my-3">
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              {countrySelected?.name}
            </Dropdown.Toggle>
            <Dropdown.Menu as={CustomMenu}>
              {countriesData?.map((item, index) => {
                const changeCountry = () => {
                  setCountrySelected(item);
                };
                return (
                  <Dropdown.Item
                    onClick={() => changeCountry()}
                    key={item?.id}
                    eventKey={1}
                  >
                    {item?.name}
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="w-100">
          <p className="my-0" style={{ fontSize: 18 }}>
            {t("phone")}
          </p>
          <input
            name="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            type="tel"
            className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
            placeholder={t("phone")}
            style={{ fontSize: 14, color: Theme.colors.darkGrey }}
          />
        </div>
        <div className="w-100">
          <p className="my-0" style={{ fontSize: 18 }}>
            {t("area")}
          </p>
          <input
            name="area"
            value={area}
            onChange={(e) => setArea(e.target.value)}
            className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
            placeholder={t("area")}
            style={{ fontSize: 14, color: Theme.colors.darkGrey }}
          />
        </div>
        <div className="w-100">
          <p className="my-0" style={{ fontSize: 18 }}>
            {t("block")}
          </p>
          <input
            name="block"
            value={block}
            onChange={(e) => setBlock(e.target.value)}
            className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
            placeholder={t("block")}
            style={{ fontSize: 14, color: Theme.colors.darkGrey }}
          />
        </div>
        <div className="w-100">
          <p className="my-0" style={{ fontSize: 18 }}>
            {t("street")}
          </p>
          <input
            name="street"
            value={street}
            onChange={(e) => setStreet(e.target.value)}
            className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
            placeholder={t("street")}
            style={{ fontSize: 14, color: Theme.colors.darkGrey }}
          />
        </div>
        <div className="w-100">
          <p className="my-0" style={{ fontSize: 18 }}>
            {t("building")}
          </p>
          <input
            name="building"
            value={building}
            onChange={(e) => setBuilding(e.target.value)}
            className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
            placeholder={t("building")}
            style={{ fontSize: 14, color: Theme.colors.darkGrey }}
          />
        </div>
        <div className="w-100">
          <p className="my-0" style={{ fontSize: 18 }}>
            {t("flat")}
          </p>
          <input
            name="flat"
            value={flat}
            onChange={(e) => setFlat(e.target.value)}
            className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
            placeholder={t("flat")}
            style={{ fontSize: 14, color: Theme.colors.darkGrey }}
          />
        </div>
        <div className="w-100">
          <p className="my-0" style={{ fontSize: 18 }}>
            {t("paci number")}
          </p>
          <input
            name="paci number"
            value={paciNumber}
            onChange={(e) => setPaciNumber(e.target.value)}
            className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
            placeholder={t("paci number")}
            style={{ fontSize: 14, color: Theme.colors.darkGrey }}
          />
        </div>
        <div className="w-100">
          <Button type="submit" variant="primary rounded-0 w-100">
            {t("Save")}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AddAddressModal;
