import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Accordion, Button, Dropdown, FormControl } from "react-bootstrap";
import SweetAlert from "react-bootstrap-sweetalert";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import Theme from "../../../../styles/theme";
import { baseUrl } from "../../../../utilts";

const AddressesList = ({ data, index, refreshPage }) => {
  const { t } = useTranslation();
  var bodyFormData = new FormData();
  const { handleSubmit, register } = useForm();
  const [addressArea, setAddressArea] = useState("");
  const [addressName, setAddressName] = useState("");
  const [addressBlock, setAddressBlock] = useState("");
  const [addressStreet, setAddressStreet] = useState("");
  const [addressBuilding, setAddressBuilding] = useState("");
  const [addressFlat, setAddressFlat] = useState("");
  const [addressPaciNumber, setAddressPaciNumber] = useState("");
  const [deletedAlert, setDeletedAlert] = useState(false);
  const [savedAlert, setSavedAlert] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countriesData, setCountriesData] = useState([]);
  const [countrySelected, setCountrySelected] = useState({
    name: t("all"),
    id: 0,
  });
  useEffect(() => {
    (async () => {
      try {
        await axios({
          method: "get",
          url: `${baseUrl}en/api/countries/`,
        })
          .then((res) => {
            setCountriesData(res.data?.context);
          })
          .catch((error) => {
            const errorToSend = error?.response?.statusText?.replace(
              / /g,
              "_"
            )(async () => {
              await axios({
                method: "post",
                url: `${baseUrl}en/api/logs/web/${errorToSend}`,
              })
                .then((res) => {
                  console.log(res);
                })
                .catch((error) => {
                  console.log(error);
                });
            })();
          });
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  const getData = () => {
    const token = localStorage.getItem("accessToken");
    (async () => {
      await axios({
        method: "get",
        url: `${baseUrl}en/api/addresses/${data.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          const response = res.data.context;
          setPhoneNumber(response?.phone);
          setAddressName(response?.name);
          setCountrySelected(response?.country);
          setAddressArea(response?.area);
          setAddressBlock(response?.block);
          setAddressStreet(response?.street);
          setAddressBuilding(response?.building);
          setAddressFlat(response?.flat);
          setAddressPaciNumber(response?.paci_number);
        })
        .catch((error) => {
          console.log("address details error", error);
        });
    })();
  };
  const SaveChanges = async () => {
    const token = localStorage.getItem("accessToken");
    try {
      await axios({
        method: "put",
        url: `${baseUrl}en/api/addresses/${data.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: bodyFormData,
      })
        .then(() => {
          setSavedAlert(true);
        })
        .catch((error) => {
          console.log("edite address error", error);
        });
    } catch (error) {
      console.log(error);
    }
  };
  const onSubmit = async (values) => {
    bodyFormData.append("name", values?.name);
    bodyFormData.append("country", countrySelected?.id);
    bodyFormData.append("phone", phoneNumber);
    bodyFormData.append("area", values?.area);
    bodyFormData.append("block", values?.block);
    bodyFormData.append("street", values?.street);
    bodyFormData.append("building", values?.building);
    bodyFormData.append("flat", values?.flat);
    bodyFormData.append("paci_number", values?.paci_number);
    SaveChanges();
  };
  const DeleteAddress = () => {
    const token = localStorage.getItem("accessToken");
    (async () => {
      await axios({
        method: "delete",
        url: `${baseUrl}en/api/addresses/${data.id}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(() => {
          refreshPage();
          setDeletedAlert(true);
        })
        .catch((error) => {
          const errorToSend = error?.response?.statusText.replace(
            / /g,
            "_"
          )(async () => {
            try {
              await axios({
                method: "post",
                url: `${baseUrl}en/api/logs/web/${errorToSend}`,
              })
                .then((res) => {
                  console.log(res);
                })
                .catch((error) => {
                  console.log(error);
                });
            } catch (error) {
              console.log(error);
            }
          })();
        });
    })();
  };
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <p
      className="d-flex justify-content-between align-items-center border-bottom"
      style={{ cursor: "pointer", color: Theme.colors.darkGrey }}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className="ml-2" style={{ fontSize: 12 }}>
        &#x25bc;
      </span>
    </p>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder={t("Type to filter Countries")}
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );
  // onClick={()=>SaveChanges()}
  return (
    <div onClick={() => getData()} className="d-flex border-bottom my-2">
      <p
        className="my-1 mx-2"
        style={{ fontSize: 12, overFlow: "auto", whiteSpace: "nowrap" }}
      >
        <b>
          {t("Address")}: ({index + 1})
        </b>
      </p>
      <Accordion className="w-100" key={index} defaultActiveKey="0">
        <Accordion.Toggle
          as={Button}
          style={{ width: 170 }}
          variant="link text-left w-100"
          eventKey="1"
        >
          <p className="m-0" style={{ fontSize: 12 }}>
            {data?.area}, <span>{data.street}</span>, <span>{data.block}</span>,{" "}
            <span>{data.building}</span>
          </p>
        </Accordion.Toggle>
        <form name="form" className="col-12" onSubmit={handleSubmit(onSubmit)}>
          <Accordion.Collapse eventKey="1">
            <div className="w-100">
              <p className="my-0" style={{ fontSize: 18 }}>
                {t("name")}
              </p>
              <input
                name="name"
                ref={register({
                  required: "Required",
                })}
                value={addressName}
                onChange={(e) => setAddressName(e.target.value)}
                className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
                placeholder={t("name")}
                style={{ fontSize: 14, color: Theme.colors.darkGrey }}
              />
            </div>
          </Accordion.Collapse>
          <Accordion.Collapse eventKey="1">
            <div className="w-100">
              <p className="my-0" style={{ fontSize: 18 }}>
                {t("Select country")}
              </p>
              <Dropdown className="w-100 my-2">
                <Dropdown.Toggle
                  as={CustomToggle}
                  id="dropdown-custom-components"
                >
                  {countrySelected?.name}
                </Dropdown.Toggle>
                <Dropdown.Menu as={CustomMenu}>
                  {countriesData?.map((item, index) => {
                    const changeCountry = () => {
                      setCountrySelected(item);
                    };
                    return (
                      <Dropdown.Item
                        onClick={() => changeCountry()}
                        key={item?.id}
                        eventKey={1}
                      >
                        {item?.name}
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </Accordion.Collapse>
          <Accordion.Collapse eventKey="1">
            <div className="w-100 mb-3">
              <p className="mb-2">{t("Phone Number")}</p>
              <PhoneInput
                ref={register}
                buttonClass="rounded-0"
                inputClass="w-100 rounded-0 border-top-0 border-right-0 border-left-0 border-bottom"
                name="phoneNumber"
                type="text"
                country={"kw"}
                enableAreaCodes={true}
                areaCodes={{ us: ["332"] }}
                inputProps={{
                  name: "phone",
                  country: "us",
                  required: true,
                  autoFocus: true,
                }}
                value={phoneNumber}
                onChange={(value) => setPhoneNumber(value)}
                required
                inputStyle={{ backgroundColor: Theme.colors.grey }}
              />
            </div>
          </Accordion.Collapse>
          <Accordion.Collapse eventKey="1">
            <div className="w-100">
              <p className="my-0" style={{ fontSize: 18 }}>
                {t("area")}
              </p>
              <input
                name="area"
                ref={register({
                  required: "Required",
                })}
                value={addressArea}
                onChange={(e) => setAddressArea(e.target.value)}
                className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
                placeholder={t("area")}
                style={{ fontSize: 14, color: Theme.colors.darkGrey }}
              />
            </div>
          </Accordion.Collapse>
          <Accordion.Collapse eventKey="1">
            <div className="w-100">
              <p className="my-0" style={{ fontSize: 18 }}>
                {t("block")}
              </p>
              <input
                name="block"
                ref={register({
                  required: "Required",
                })}
                type="number"
                value={addressBlock}
                onChange={(e) => setAddressBlock(e.target.value)}
                className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
                placeholder={t("block")}
                style={{ fontSize: 14, color: Theme.colors.darkGrey }}
              />
            </div>
          </Accordion.Collapse>
          <Accordion.Collapse eventKey="1">
            <div className="w-100">
              <p className="my-0" style={{ fontSize: 18 }}>
                {t("street")}
              </p>
              <input
                name="street"
                ref={register({
                  required: "Required",
                })}
                value={addressStreet}
                onChange={(e) => setAddressStreet(e.target.value)}
                className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
                placeholder={t("street")}
                style={{ fontSize: 14, color: Theme.colors.darkGrey }}
              />
            </div>
          </Accordion.Collapse>
          <Accordion.Collapse eventKey="1">
            <div className="w-100">
              <p className="my-0" style={{ fontSize: 18 }}>
                {t("building")}
              </p>
              <input
                name="building"
                ref={register({
                  required: "Required",
                })}
                type="number"
                value={addressBuilding}
                onChange={(e) => setAddressBuilding(e.target.value)}
                className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
                placeholder={t("building")}
                style={{ fontSize: 14, color: Theme.colors.darkGrey }}
              />
            </div>
          </Accordion.Collapse>
          <Accordion.Collapse eventKey="1">
            <div className="w-100">
              <p className="my-0" style={{ fontSize: 18 }}>
                {t("flat")}
              </p>
              <input
                name="flat"
                ref={register({
                  required: "Required",
                })}
                type="number"
                value={addressFlat}
                onChange={(e) => setAddressFlat(e.target.value)}
                className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
                placeholder={t("flat")}
                style={{ fontSize: 14, color: Theme.colors.darkGrey }}
              />
            </div>
          </Accordion.Collapse>
          <Accordion.Collapse eventKey="1">
            <div className="w-100">
              <p className="my-0" style={{ fontSize: 18 }}>
                {t("paci number")}
              </p>
              <input
                name="paci_number"
                ref={register({
                  required: "Required",
                })}
                type="number"
                value={addressPaciNumber}
                onChange={(e) => setAddressPaciNumber(e.target.value)}
                className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
                placeholder={t("paci number")}
                style={{ fontSize: 14, color: Theme.colors.darkGrey }}
              />
            </div>
          </Accordion.Collapse>
          <Accordion.Collapse className="mb-3" eventKey="1">
            <div className="d-flex justify-content-end">
              <Button type="submit" variant="primary rounded-0 mx-1">
                Save Changes
              </Button>
              <Button
                onClick={() => DeleteAddress()}
                variant="danger rounded-0 mx-1"
              >
                {t("Delete")}
              </Button>
            </div>
          </Accordion.Collapse>
        </form>
      </Accordion>
      {deletedAlert && (
        <SweetAlert
          type="success"
          title={t("address deleted successfully")}
          onConfirm={() => setDeletedAlert(false)}
          focusCancelBtn={true}
        />
      )}
      {savedAlert && (
        <SweetAlert
          type="success"
          title={t("changes saved successfully")}
          onConfirm={() => setSavedAlert(false)}
          focusCancelBtn={true}
        />
      )}
    </div>
  );
};

export default AddressesList;
