import React, { useState, useEffect, useContext, useRef } from "react";
import { Image } from "react-bootstrap";
import { BsArrowUp } from "react-icons/bs";
import axios from "axios";
import ReactLoading from "react-loading";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Context as AuthContext } from "../../Context/AuthContext";
import RecentViewed from "./component/RecentViewed";
import Theme from "../../styles/theme";
import AteejLogo from "../../assets/images/AteejLogo.png";
import "./home.scss";
import { baseUrl } from "../../utilts";

const Home = () => {
  const navigate = useNavigate();
  const { state } = useContext(AuthContext);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [showScroll, setShowScroll] = useState(false);
  const [data, setData] = useState([]);
  const [banners, setBanners] = useState([]);
  const isBottom = (el) => {
    return el?.getBoundingClientRect().bottom <= window.innerHeight;
  };
  useEffect(() => {
    document.addEventListener("scroll", trackScrolling);
    // document.removeEventListener('scroll', trackScrolling);

    // return ()=>{

    // }
  }, []);
  const trackScrolling = () => {
    const wrappedElement = document.getElementById("thisText");
    if (isBottom(wrappedElement)) {
      console.log("header bottom reached");
      document.removeEventListener("scroll", trackScrolling);
    }
  };
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const token = localStorage.getItem("accessToken");
      try {
        if (token != null) {
          await axios({
            method: "get",
            url: `${baseUrl}en/api/items/home/`,
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
            .then((res) => {
              const homeData = res.data;
              setData(homeData);
              setBanners(homeData?.banners);
            })
            .catch((error) => {
              console.log({ error });
              setIsLoading(true);
              const errorToSend = error?.response?.statusText?.replace(
                / /g,
                "_"
              );
              (async () => {
                try {
                  await axios({
                    method: "post",
                    url: `${baseUrl}en/api/logs/web/${errorToSend}`,
                  })
                    .then((res) => {
                      console.log(res);
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                  setIsLoading(false);
                } catch (error) {
                  setIsLoading(false);
                  console.log(error);
                }
              })();
            });
        } else {
          await axios({
            method: "get",
            url: `${baseUrl}en/api/items/home/`,
          })
            .then((res) => {
              console.log({ res });
              const homeData = res.data;
              setData(homeData);
              setBanners(homeData?.banners);
            })
            .catch((error) => {
              console.log({ error });
              setIsLoading(true);
              const errorToSend = error?.response?.statusText.replace(
                / /g,
                "_"
              );
              error.response.status == 404 &&
                navigate("/NotFound")(async () => {
                  try {
                    await axios({
                      method: "post",
                      url: `${baseUrl}en/api/logs/web/${errorToSend}`,
                    })
                      .then(() => {})
                      .catch((error) => {
                        console.log(error);
                      });
                    setIsLoading(false);
                  } catch (error) {
                    setIsLoading(false);
                    console.log(error);
                  }
                })();
            });
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, [state?.token]);
  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };
  window.addEventListener("scroll", checkScrollTop);
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  const ItemBody = useRef(null);
  const navigateToMainPage = () => scrollToRef(ItemBody);
  const bannersTypeOne = banners.filter((item) => item?.banner_type === "1");
  return (
    <>
      {isLoading === true ? (
        <div
          style={{ height: "100vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <ReactLoading
            type="bubbles"
            height={150}
            width={150}
            color={Theme.colors.darkGrey}
          />
        </div>
      ) : (
        <div className="container-fluid mb-5">
          <div ref={ItemBody} className="row">
            <div className="col-12 d-none d-lg-block px-5 custom-drawer">
              {/* {console.log("***removed here", data)} */}
              {/* <CarouselSlider data={data?.carousel} /> */}
            </div>
            <div className="col-12">
              <RecentViewed
                data={data?.context}
                banners={banners}
                recent={data?.recent}
              />
            </div>
            {/* <div className="col-12">
              <Brands data={data?.brands} />
            </div> */}
            {bannersTypeOne?.map((item, index) => {
              return (
                <div className="my-5" key={item?.id}>
                  {item?.theme === "2" ? (
                    <div
                      className="row col-12 my-5 mx-auto"
                      style={{ backgroundColor: Theme.colors.extraDarkBlue }}
                    >
                      <div className="col-12 col-lg-4 d-flex flex-column align-items-center align-items-lg-start mx-auto p-5">
                        <h1
                          className="text-center text-lg-left"
                          style={{ color: "#C2F2EF" }}
                        >
                          {item?.title}
                        </h1>
                        <p
                          className="text-center text-lg-left"
                          style={{ color: "#C2F2EF" }}
                        >
                          {item?.description}
                        </p>
                        <button className="button">
                          <span>{t("Learn more")}</span>
                        </button>
                      </div>
                      <div className="col-8 d-none d-lg-flex justify-content-end">
                        <Image
                          alt={item?.title}
                          src={item?.image}
                          className="w-100"
                          style={{
                            objectFit: "fill",
                            color: Theme.colors.white,
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                </div>
              );
            })}
            <div className="col-12 col-lg-10 mx-auto">
              {bannersTypeOne?.map((item, index) => {
                return (
                  <div className="my-5" key={item?.id}>
                    {item?.theme === "3" ? (
                      <div
                        className="row border rounded p-0"
                        style={{ color: "inherit" }}
                      >
                        <div className="w-50 d-flex flex-column align-items-center align-items-lg-start mx-auto p-2">
                          <p style={{ fontSize: 12 }}>{t("Featured")}</p>
                          <Image
                            onClick={() => navigateToMainPage()}
                            style={{ objectFit: "cover", cursor: "pointer" }}
                            width="200"
                            src={AteejLogo}
                          />
                          <h4 className="text-center text-lg-right">
                            {item?.title}
                          </h4>
                          <p style={{ color: Theme.colors.darkGrey }}>
                            {item?.description}
                          </p>
                          <button className="button">
                            <span>{t("Get your thing")}</span>
                          </button>
                        </div>
                        <div className="w-50 h-100 d-none d-lg-flex">
                          <Image
                            className="w-100"
                            style={{ objectFit: "fill", cursor: "pointer" }}
                            alt={item?.title}
                            src={item?.image}
                          />
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                );
              })}
            </div>
            {/* <h1 className="col-12" id="thisText">
              text for test
            </h1> */}
            <div
              style={{
                display: showScroll ? "flex" : "none",
                position: "fixed",
                bottom: 50,
                right: 50,
                color: Theme.colors.white,
                backgroundColor: "#f4511e",
                cursor: "pointer",
                padding: 30,
                borderRadius: 100,
              }}
              onClick={scrollTop}
            >
              <BsArrowUp className="scrollTop rounded-circle" size={32} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Home;
