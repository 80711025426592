import React, { useState, useEffect } from "react";
import ReactLoading from "react-loading";
import axios from "axios";
import { BiBusSchool } from "react-icons/bi";
import { AiOutlinePhone, AiOutlinePlusCircle } from "react-icons/ai";
import { IoMdReorder } from "react-icons/io";
import { Button, Dropdown, FormControl, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AiFillCheckCircle } from "react-icons/ai";
import SweetAlert from "react-bootstrap-sweetalert";

import Paypal from "../../assets/images/paypal.png";
import VisaCheckout from "../../assets/images/VisaCheckout.png";
import MasterPass from "../../assets/images/masterPass.png";
import AddAddressModal from "../../pages/profile/component/AddAddressModal";
import Card from "./component/Card";
import PaymentCard from "./component/PaymentCard";
import Theme from "../../styles/theme";
import { baseUrl } from "../../utilts";
const PaymentMethods = [
  {
    id: 1,
    title: "Paypal",
    logo: Paypal,
  },
  {
    id: 8,
    title: "Visa Checkout",
    logo: VisaCheckout,
  },
  {
    id: 9,
    title: "Masterpass",
    logo: MasterPass,
  },
];
const Checkout = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [addAddressModal, setAddAddressModal] = useState(false);
  const [itemAdded, setItemAdded] = useState(false);
  const [errorAddItems, setErrorAddItems] = useState(false);
  const [checkingCode, setCheckingCode] = useState(false);
  const [reloadPage, setReloadPage] = useState(false);
  const [ordersItems, setOrdersItems] = useState([]);
  const [maxQuantity, setMaxQuantity] = useState([]);
  const [adresses, setAddress] = useState([]);
  const [discountValue, setDiscountValue] = useState({});
  const [personData, setPersonData] = useState({});
  const [countrySelected, setCountrySelected] = useState({});
  const [discountInput, setDiscountInput] = useState("");
  const [notFoundCode, setNotFoundCode] = useState("");
  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const Items = JSON.parse(localStorage.getItem("itemsArray")) || [];
      setOrdersItems(Items);
      const availableQuantity =
        JSON.parse(localStorage.getItem("availableQuantity")) || [];
      setMaxQuantity(availableQuantity);
      const token = localStorage.getItem("accessToken");
      try {
        await axios({
          method: "get",
          url: `${baseUrl}en/api/auth_user/profile/`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            const response = res.data?.context;
            setPersonData(response);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            console.log(error);
          });
        await axios({
          method: "get",
          url: `${baseUrl}en/api/addresses/`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then((res) => {
            setIsLoading(false);
            const response = res.data?.context;
            setAddress(response);
          })
          .catch((error) => {
            (async () => {
              setIsLoading(true);
              try {
                await axios({
                  method: "post",
                  url: `${baseUrl}en/api/logs/web/${error?.response?.statusText}`,
                })
                  .then(() => {})
                  .catch((error) => {
                    console.log(error);
                  });
                setIsLoading(false);
              } catch (error) {
                setIsLoading(false);
                console.log(error);
              }
            })();
          });
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    })();
  }, [reloadPage]);
  const navigateToProfile = () => {
    navigate({
      pathname: `/Profile/${personData.first_name} ${personData.last_name}`,
    });
  };
  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      checkCoupon();
    }
  };
  const checkCoupon = async () => {
    setCheckingCode(true);
    try {
      const token = localStorage.getItem("accessToken");
      await axios({
        method: "get",
        url: `${baseUrl}en/api/coupon/discount/${discountInput}/${totalPrice}`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((res) => {
          setNotFoundCode("");
          setIsLoading(false);
          const discountResponse = res.data.context;
          setDiscountValue(discountResponse);
        })
        .catch((error) => {
          setNotFoundCode(t("code is not found"));
        });
      setCheckingCode(false);
    } catch (error) {
      setCheckingCode(false);
      console.log(error);
    }
  };
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <p
      className="px-3 py-1 rounded-pill"
      style={{
        cursor: "pointer",
        backgroundColor: Theme.colors.grey,
        borderColor: Theme.colors.darkGrey,
        borderWidth: 2,
        borderStyle: "solid",
      }}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className="ml-2" style={{ fontSize: 12 }}>
        &#x25bc;
      </span>
    </p>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder={t("Type to filter Countries")}
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );
  const pushAddAddressModal = () => {
    setAddAddressModal(true);
  };
  const closeAddedAlert = () => {
    setItemAdded(false);
    setErrorAddItems(false);
    setReloadPage(!reloadPage);
  };
  const totalPrice = maxQuantity.reduce(
    (accumulator, current) =>
      accumulator + current.price_total * current.availableQuantity,
    0
  );
  return (
    <>
      {isLoading === true ? (
        <div
          style={{ height: "100vh" }}
          className="d-flex justify-content-center align-items-center"
        >
          <ReactLoading
            type="bubbles"
            height={150}
            width={150}
            color={Theme.colors.darkGrey}
          />
        </div>
      ) : (
        <div className="container-fluid h-100 my-5">
          <div className="row">
            <div className="col-12">
              <h5>
                {t("Hi!")}{" "}
                <span
                  style={{
                    textTransform: "uppercase",
                    color: Theme.colors.blue,
                    cursor: "pointer",
                  }}
                  onClick={() => navigateToProfile()}
                >
                  {personData?.first_name} {personData?.last_name}
                </span>
                , {t("your order is being shipped")}
              </h5>
            </div>
            <div className="col-6 col-md-3 my-3">
              <p>
                <BiBusSchool size={15} /> {t("Your order will be ship to")}:
              </p>
              {adresses.length > 0 ? (
                <div>
                  <Dropdown>
                    <Dropdown.Toggle
                      as={CustomToggle}
                      id="dropdown-custom-components"
                    >
                      {countrySelected?.area == undefined
                        ? t("all")
                        : `${countrySelected?.area}, ${countrySelected?.street}, ${countrySelected?.block}, ${countrySelected?.building}`}
                    </Dropdown.Toggle>
                    <Dropdown.Menu as={CustomMenu}>
                      {adresses?.map((item, index) => {
                        return (
                          <Dropdown.Item
                            onClick={() => setCountrySelected(item)}
                            key={item?.id}
                            eventKey={1}
                          >{`${item?.name}, ${item?.area} ${item?.street}, ${item?.block}, ${item?.building}`}</Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                  <div className="w-50 d-flex justify-content-center align-items-center">
                    <p
                      onClick={() => pushAddAddressModal()}
                      className="my-0 p-1 rounded"
                      style={{
                        cursor: "pointer",
                        backgroundColor: Theme.colors.grey,
                      }}
                    >
                      <span className="mx-1">
                        <AiOutlinePlusCircle size={15} />
                      </span>
                      {t("Add new address")}
                    </p>
                  </div>
                </div>
              ) : (
                <div className="w-50 d-flex justify-content-center align-items-center">
                  <p
                    onClick={() => pushAddAddressModal()}
                    className="my-0 p-1 rounded"
                    style={{
                      cursor: "pointer",
                      backgroundColor: Theme.colors.grey,
                    }}
                  >
                    <span className="mx-1">
                      <AiOutlinePlusCircle size={15} />
                    </span>
                    {t("Add new address")}
                  </p>
                </div>
              )}
            </div>
            <div className="col-6 col-md-3 my-3">
              <p>
                <AiOutlinePhone size={15} /> {t("Phone Number")}:
              </p>
              <p>{personData?.phone}</p>
            </div>
            <h5 className="col-12">{t("Your orders")}:</h5>
            <div className="col-11 ml-3 border">
              <div>
                <p className="m-4">
                  <b>
                    <span>
                      <IoMdReorder size={15} />
                    </span>
                    {t("order total")}:
                  </b>
                </p>
                <div className="d-flex justify-content-between w-100 w-md-25">
                  <p style={{ fontSize: 15 }}>
                    <b>{t("total price")}</b>
                  </p>
                  {discountValue?.discount != null ? (
                    <del>
                      <i>
                        <p
                          style={{ fontSize: 15, color: Theme.colors.darkGrey }}
                        >
                          <b>US ${totalPrice}</b>
                        </p>
                      </i>
                    </del>
                  ) : (
                    <p style={{ fontSize: 15 }}>
                      <b>US ${totalPrice}</b>
                    </p>
                  )}
                </div>
                <div className="d-flex justify-content-between w-100 w-md-25">
                  <div className="d-flex">
                    <p
                      className="mx-2"
                      style={{ color: Theme.colors.black, fontSize: 12 }}
                    >
                      {t("add Coupon")}:{" "}
                      <span>
                        <input
                          onKeyPress={handleKeypress}
                          value={discountInput}
                          onChange={(e) => setDiscountInput(e.target.value)}
                          className="border-top-0 border-right-0 border-left-0 border-bottom my-2"
                        />
                      </span>
                    </p>
                    <Button
                      onClick={() => checkCoupon()}
                      variant="primary py-0 mx-2 rounded-0"
                    >
                      {t("check coupon")}
                    </Button>
                    {discountValue?.discount != null && (
                      <p
                        className="mx-2 my-auto"
                        style={{ color: Theme.colors.black, fontSize: 12 }}
                      >
                        <span>
                          {t("Confirmed")}{" "}
                          <AiFillCheckCircle
                            color={Theme.colors.green}
                            size={15}
                          />{" "}
                        </span>
                        {discountValue?.discount}%
                      </p>
                    )}
                    {checkingCode === true && (
                      <ReactLoading
                        className="my-auto"
                        type="spinningBubbles"
                        height={20}
                        width={20}
                        color={Theme.colors.darkGrey}
                      />
                    )}
                    {notFoundCode != "" && (
                      <p className="my-auto" style={{ fontSize: 12 }}>
                        {notFoundCode}
                      </p>
                    )}
                  </div>
                  {discountValue?.discount != null && (
                    <p style={{ fontSize: 15 }}>
                      <b>US ${discountValue?.net_discount}</b>
                    </p>
                  )}
                </div>
                <div className="d-flex justify-content-between w-100 w-md-25">
                  <p style={{ fontSize: 12 }}>{t("Shipping")}</p>
                  <p style={{ fontSize: 12 }}>{t("Free")}</p>
                </div>
                <div>
                  {ordersItems?.length > 0 &&
                    ordersItems?.map((item, index) => {
                      return (
                        <Card
                          key={item?.id}
                          data={item}
                          maxQuantity={maxQuantity}
                        />
                      );
                    })}
                </div>
                <div className="d-flex justify-content-between w-100 w-md-25 my-3">
                  <div className="w-100">
                    <h6 className="mb-4">{t("choose your Payment method")}:</h6>
                    <div className="d-flex align-items-center">
                      {PaymentMethods?.map((item) => (
                        <div key={item} className="mb-3">
                          <PaymentCard data={item} />
                        </div>
                      ))}
                    </div>
                  </div>
                  <Button
                    onClick={() => navigate(-1)}
                    style={{
                      color: "#007bff",
                      overFlow: "auto",
                      whiteSpace: "nowrap",
                    }}
                    variant="light rounded-0 px-4"
                  >
                    {t("back to Cart")}
                  </Button>
                </div>
                <Modal
                  show={addAddressModal}
                  onHide={() => setAddAddressModal(false)}
                >
                  <AddAddressModal
                    setAddAdressesModal={() => setAddAddressModal(false)}
                    setItemAdded={() => setItemAdded(true)}
                    setErrorAddItems={() => setErrorAddItems(true)}
                  />
                </Modal>
                {itemAdded && (
                  <SweetAlert
                    type="success"
                    title={t("address added successfully")}
                    onConfirm={() => closeAddedAlert()}
                    focusCancelBtn={true}
                  />
                )}
                {errorAddItems && (
                  <SweetAlert
                    type="error"
                    title={t("error with adding address")}
                    onConfirm={() => closeAddedAlert()}
                    focusCancelBtn={true}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Checkout;
