import React,{useState} from "react";
import axios from "axios";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { Image,Button } from "react-bootstrap";
import { baseUrl } from "../../../../../../utilts";

const Card = ({data,CloseModal})=>{
    const { t, } = useTranslation();
    const navigate = useNavigate();
    const [followState,setFollowState]=useState(data?.is_following);
    const token = localStorage.getItem("accessToken");
    const ChangeFollowState = async()=>{
        token!=null?
        await axios({
            method:"post",
            url:`${baseUrl}en/api/tweets/followunfollow/${data?.user_id}`,
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }).then(res=>{
            setFollowState(!followState)
        }).catch(error=>{
            console.log(error);
        }):
        navigate({
            pathname: '/login',
            state: {
                route: "/"
            }
        })
    }
    const size = 60;
    const navigateToUserProfile =()=>{
        CloseModal()
        navigate({
            pathname: `/User/${data?.username}`,
            state: {
                _id: data?.user_id,
            }
        })
    }
    const followPerson = t('follow');
    const unfollowPerson = t('unfollow');
    return(
        <div className="d-flex align-items-center justify-content-between m-2">
            <div className="d-flex align-items-center">
                <Image onClick={()=>navigateToUserProfile()} className="rounded-circle" style={{objectFit:"cover",cursor:"pointer"}} width={size} height={size} src={data?.image}/>
                <p className="my-0 mx-2">{data?.username}</p>
            </div>
            <Button onClick={()=>ChangeFollowState()} variant="primary rounded-0 px-5 py-1 m-1">
                {followState===true?unfollowPerson:followPerson}
            </Button>
        </div>
    )
}

export default Card;