export * from "./Constants";
export * from "./ConstantsAxios";
export * from "./Fetching";

export const logoutUtl = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("userId");
  localStorage.removeItem("itemsArray");
  localStorage.removeItem("availableQuantity");
  localStorage.removeItem("userData");
};

export const baseUrl =
  process.env.NODE_ENV === "production"
    ? "http://api.ateej.net/" // ✅ Production URL
    : "/api/";

export const saveUserDataLocaly = (response) => {
  localStorage.setItem("accessToken", response?.token?.access);
  localStorage.setItem("userData", JSON.stringify(response?.userprofile));
  localStorage.setItem("userId", response?.userprofile?.id);
};
