import React,{useContext} from "react";
import { Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import Theme from "../../../../styles/theme";
import {Context as MainContext} from "../../../../Context/mainContext";

const Card = ({ item,index }) => {
    const { changeItemId} = useContext(MainContext);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const rootTitle = item?.root?.title?.replace(/ /g,"_")
    const categoryTitle = item?.category?.title?.replace(/ /g,"_")
    const navigateToItemDetails = () => {
        navigate({
                pathname: `/${rootTitle}/${categoryTitle}/${item?.id}`,
                state: {
                    _id: item?.id,
                }
            })
        changeItemId(item?.id)
    }
    const width=100;
    return (
        <div className="d-flex justify-content-between px-3 m-2 border">
            <div>
                <div className="d-flex">
                    <Image onClick={()=>navigateToItemDetails()} style={{cursor:"pointer"}} className="my-2" width="170" height="170" src={item.thumbnail} />
                    <div className="d-flex flex-column justify-content-between">
                        <div className="d-flex">
                            <p className="ml-3" style={{ fontSize: 15, fontWeight: "bold", width,textOverflow:"ellipsis",whiteSpace:"nowrap",overflow:"hidden" }}>{item?.title}</p>
                            <p className="ml-3" style={{ fontSize: 15, fontWeight: "bold" }}>{item?.price_total}$ US</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card;