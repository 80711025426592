import React, { useState , useEffect,useContext } from "react";
import { BsFillPersonFill } from "react-icons/bs";
import axios from "axios";
import {Image} from "react-bootstrap";

import Theme from "../../../../styles/theme";
import {Context as mainContext} from "../../../../Context/mainContext";
import { baseUrl } from "../../../../utilts";

const MessagesContainer =({reload,userId})=>{
    const {state,showMessagesContainer} = useContext(mainContext);
    const [messagesData,setMessagesData]=useState([]);
    var token;
    useEffect(() => {
            token = localStorage.getItem("accessToken");
            (async()=>{
                axios({
                    method:"get",
                    url: `${baseUrl}en/api/conversations/user/${state?.reciverId}`,
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    }
                }).then(res=>{
                    const response = res.data;
                    setMessagesData(response?.context);
                    response?.context?.length>0?showMessagesContainer(true):showMessagesContainer(false)
                }).catch(error=>{
                    console.log(error);
                })
            })()
        },[reload,state?.reciverId])
    const size = 50;
    // console.log("messagesData",messagesData);
    return(
        <>
            {
                messagesData.map((item,index)=>{
                    const MessageDirection = state?.reciverId===item?.recipient?.id?"text-left my-0":state?.reciverId===item?.sender?.id?"text-left my-0":"text-right my-0";
                    const reciverImage = state?.reciverId===item?.recipient?.id?item?.recipient?.image:item?.sender?.image;
                    const senderImage = state?.reciverId===item?.recipient?.id?item?.sender?.image:item?.recipient?.image;
                    return(
                        <>
                        {
                            item?.sender?.id!==item?.recipient?.id&&
                            <div
                                key={index}
                                className={MessageDirection}
                            >
                                {
                                    item?.recipient?.id===state?.reciverId?
                                    <div className={state?.reciverId!==item?.recipient?.id?"my-2 d-flex flex-row-reverse align-items-center justify-content-start":"my-2 d-flex flex-row align-items-center justify-content-end"}>
                                    <span className="rounded p-2" style={{backgroundColor:Theme.colors.grey}}>{item?.body}</span>
                                    {
                                        item?.sender?.image!==null?<Image className="rounded-circle mx-2" style={{objectFit:"cover"}} width={size} height={size} src={senderImage}/>:
                                        <BsFillPersonFill size={30}/>
                                    }
                                    </div>:
                                    <div className={state?.reciverId===item?.recipient?.id?"my-2 d-flex flex-row-reverse align-items-center justify-content-end":"my-2 d-flex flex-row align-items-center justify-content-start"}>
                                    {
                                        item?.recipient?.image!==null?<Image className="rounded-circle mx-2" style={{objectFit:"cover"}} width={size} height={size} src={reciverImage}/>:
                                        <BsFillPersonFill size={30}/>
                                    }
                                    <span className="rounded p-2" style={{backgroundColor:Theme.colors.darkGrey,color:Theme.colors.white}}>{item?.body}</span>
                                    </div>
                                }
                            </div>
                        }
                        </>
                    )
                })
            }
        </>
    )
}

export default MessagesContainer;