import React, { useState, useContext } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Button, Dropdown, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import axios from "axios";

import QuickSearchResult from "./component/QuickSearchResult";
import { Context as MainContext } from "../../../../../Context/mainContext";
import Theme from "../../../../../styles/theme";
import "./SearchBox.scss";
import { baseUrl } from "../../../../../utilts";

const SearchBox = ({ data }) => {
  const { searchText, pushSearchFunction } = useContext(MainContext);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchTextState, setSearchText] = useState("");
  const [responseData, setResponseData] = useState([]);
  const emptyArray = [];
  const navigateToDetails = () => {
    const textAfterDeletingEndingSpacing = searchTextState.trim();
    const refactoredTextSearch = textAfterDeletingEndingSpacing.replace(
      /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g,
      ""
    );
    const textAfterReplacingSpacingWithUnderScore = refactoredTextSearch.replace(
      / /g,
      "_"
    );
    searchText(textAfterReplacingSpacingWithUnderScore);
    navigate({
      pathname: "/Search",
    });
    pushSearchFunction();
  };
  const handleKeypress = (e) => {
    if (e.key === "Enter") {
      navigateToDetails();
    }
  };
  const startSearch = async (e) => {
    setSearchText(e.target.value);
    const textAfterDeletingEndingSpacing = searchTextState.trim();
    const refactoredTextSearch = textAfterDeletingEndingSpacing.replace(
      /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g,
      ""
    );
    const textAfterReplacingSpacingWithUnderScore = refactoredTextSearch.replace(
      / /g,
      "_"
    );
    try {
      await axios({
        method: "get",
        url: `${baseUrl}en/api/items/search/sug/${textAfterReplacingSpacingWithUnderScore}`,
      })
        .then((res) => {
          const response = res?.data?.context;
          setResponseData(response);
        })
        .catch((error) => {
          setResponseData(emptyArray);
          console.log(error);
        });
    } catch (error) {
      const errorToSend = error?.response?.statusText?.replace(/ /g, "_");
      try {
        await axios({
          method: "post",
          url: `${baseUrl}en/api/logs/web/${errorToSend}`,
        })
          .then((res) => {
            console.log(res.data.context);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };
  const classNameContent =
    responseData.length > 0
      ? "rounded shadow border-0 p-2 custome_Search_dropDown"
      : "d-none rounded shadow border-0 p-2";
  return (
    <>
      <Dropdown className="w-100">
        <div className="d-flex">
          <div
            style={{ backgroundColor: Theme.colors.white }}
            className="w-75 d-flex px-2 justify-content-center align-items-center border rounded"
          >
            <div className="w-100 mx-auto d-flex p-0 justify-content-start align-self-center">
              <AiOutlineSearch
                onClick={() => navigateToDetails()}
                style={{ cursor: "pointer" }}
                className="d-none d-md-flex align-self-center"
                color={Theme.colors.darkGrey}
                size={22}
              />
              <Dropdown.Toggle
                variant="light btn-sm w-100"
                id="dropdown-basic"
                style={{ color: "#fff" }}
              >
                <Form.Control
                  value={searchTextState}
                  onChange={(value) => startSearch(value)}
                  onKeyPress={handleKeypress}
                  className="w-100 border-0 rounded-0"
                  placeholder={t("Search for any thing")}
                />
              </Dropdown.Toggle>
            </div>
            <div className="d-none d-md-flex border-left">
              {/* <DropDown
                responsive={false}
                title={t("All Categories")}
                items={data}
              /> */}
            </div>
          </div>
          <Button
            onClick={() => navigateToDetails()}
            className="w-25 mx-4 py-2 rounded-0"
            variant="primary"
          >
            {t("Search")}
          </Button>
        </div>
        <Dropdown.Menu className={classNameContent}>
          {responseData.length > 0 &&
            responseData?.map((item, index) => {
              return <QuickSearchResult key={index} data={item} />;
            })}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default SearchBox;
