import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Form, Button, Image } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";

import { Context as AuthContext } from "../../Context/AuthContext";
import Theme from "../../styles/theme";
import AteejLogo from "../../assets/images/AteejLogo.png";
import { useApi } from "../../hooks/useFetch";
import { baseUrl } from "../../utilts";

const Verification = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { state, changeVerifyId } = useContext(AuthContext);
  const [postVerifyCode, verifyResponseStatus] = useApi();
  const [verifyCode, setVerifyCode] = useState("");
  const [successVerified, setSuccessVerified] = useState(false);
  const [errorVerified, setErrorVerified] = useState(false);
  const [checkVerifyTypeAlert, setCheckVerifyTypeAlert] = useState(false);
  const [errorChangingVerifiedType, setErrorChangingVerifiedType] = useState(
    false
  );
  const token = localStorage.getItem("accessToken");
  const email = localStorage.getItem("email");
  const phone = localStorage.getItem("phone");
  const formData = new FormData();

  const toggleVerifyCode = async () => {
    formData.append("code", verifyCode);
    formData.append("notification_id", "hXBiA0K7ZgJEa316bxe58u");
    formData.append("device_type", "web");
    formData.append("choice", "1");
    postVerifyCode("post", `en/api/auth_user/verify/`, {}, formData);
    // try {
    //   await axios({
    //     method: "post",
    //     url: `${baseUrl}en/api/auth_user/verify/${verifyCode}`,
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //     data: {
    //       email: email,
    //     },
    //   })
    //     .then((res) => {
    //       setSuccessVerified(true);
    //       setErrorVerified(false);
    //     })
    //     .catch((error) => {
    //       setSuccessVerified(false);
    //       setErrorVerified(true);
    //       const errorToSend = error?.response?.statusText?.replace(
    //         / /g,
    //         "_"
    //       );(async () => {
    //         try {
    //           await axios({
    //             method: "post",
    //             url: `${baseUrl}en/api/logs/web/${errorToSend}`,
    //           })
    //             .then((res) => {
    //               console.log(res);
    //             })
    //             .catch((error) => {
    //               console.log(error);
    //             });
    //         } catch (error) {
    //           console.log(error);
    //         }
    //       })();
    //     });
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const closeSuccessVerifiedAlert = () => {
    setSuccessVerified(false);
    navigate("/");
  };

  const closeErrorVerifiedAlert = () => {
    setErrorVerified(false);
  };

  const toggleVerifyType = async () => {
    changeVerifyId(state?.verifyId === 1 ? 2 : 1);
    await axios({
      method: `post`,
      url: `${baseUrl}en/api/auth_user/verify/by/${state?.verifyId}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: { email: email },
    })
      .then((res) => {
        setCheckVerifyTypeAlert(true);
        setErrorChangingVerifiedType(false);
      })
      .catch((error) => {
        setCheckVerifyTypeAlert(true);
        setErrorChangingVerifiedType(false);
        const errorToSend = error?.response?.statusText?.replace(
          / /g,
          "_"
        )(async () => {
          try {
            await axios({
              method: "post",
              url: `${baseUrl}en/api/logs/web/${errorToSend}`,
            })
              .then((res) => {
                console.log(res);
              })
              .catch((error) => {
                console.log(error);
              });
          } catch (error) {
            console.log(error);
          }
        })();
      });
  };

  const SuccessCheckAlertTitle = () => (
    <p>
      {t("check your")} {state?.verifyId === 1 ? t("mobile phone") : t("email")}{" "}
      <span style={{ fontSize: 15 }}>
        <br />
        {state?.verifyId === 1 ? phone : email}
      </span>
    </p>
  );

  const closeSuccessChangeCheckAlert = () => {
    setCheckVerifyTypeAlert(false);
  };

  const closeErrorChangeCheckAlert = () => {
    setErrorChangingVerifiedType(false);
  };

  const toggleSkipButton = () => {
    navigate("/");
  };

  useEffect(() => {
    if (verifyResponseStatus === 200) {
      setSuccessVerified(true);
      setErrorVerified(false);
    } else {
      setSuccessVerified(false);
      setErrorVerified(true);
    }
  }, [verifyResponseStatus]);

  return (
    <div className="container mb-5">
      <div className="row">
        <div className="col-12 col-md-6 order-1 order-md-0">
          <h5>{t("Enter verification code")}</h5>
          <p className="my-0" style={{ color: Theme.colors.darkGrey }}>
            {t(
              "please refer to this mobile number and enter code you received"
            )}
          </p>
          <div className="d-flex mt-3">
            <Form.Control
              value={verifyCode}
              onChange={(e) => setVerifyCode(e.target.value)}
              className="border-top-0 border-right-0 border-left-0 border-bottom rounded-0 w-50"
              type="text"
              placeholder={t("Enter code")}
            />
            <Button
              onClick={() => toggleVerifyCode()}
              variant="primary rounded-0 mx-3 px-5"
            >
              {t("Verify")}
            </Button>
          </div>
        </div>
        <div className="col-12 col-md-6 order-0 order-md-1 d-flex flex-column align-items-center">
          <h5>{t("Welcome to Ateej")}</h5>
          <Image
            width="200"
            height="100"
            style={{ objectFit: "contain" }}
            src={AteejLogo}
          />
        </div>
        <div className="col-12 my-3 order-3">
          <p
            className="my-0 text-left"
            style={{ color: Theme.colors.darkGrey, fontSize: 12 }}
          >
            {t("you can skip this step")}
          </p>
        </div>
        <div className="col-12 d-flex flex-column flex-md-row justify-content-center justify-content-md-between order-4 mt-2">
          <Button
            onClick={() => toggleVerifyType()}
            variant="primary rounded-0 px-5 my-2"
          >
            {state?.verifyId === 1
              ? t("Verify by email instead")
              : t("Verify by phone number instead")}
          </Button>
          <Button
            onClick={() => toggleSkipButton()}
            variant="info rounded-0 px-5 my-2"
          >
            {t("Skip")}
          </Button>
        </div>
        {successVerified && (
          <SweetAlert
            type="success"
            title={t("account verified successfully")}
            onConfirm={() => closeSuccessVerifiedAlert()}
            focusCancelBtn={true}
          />
        )}
        {errorVerified && (
          <SweetAlert
            type="error"
            title={t("error with verifying account")}
            onConfirm={() => closeErrorVerifiedAlert()}
            focusCancelBtn={true}
          />
        )}
        {checkVerifyTypeAlert && (
          <SweetAlert
            type="success"
            title={SuccessCheckAlertTitle()}
            onConfirm={() => closeSuccessChangeCheckAlert()}
            focusCancelBtn={true}
          />
        )}
        {errorChangingVerifiedType && (
          <SweetAlert
            type="error"
            title={t("error with changing verification type")}
            onConfirm={() => closeErrorChangeCheckAlert()}
            focusCancelBtn={true}
          />
        )}
      </div>
    </div>
  );
};

export default Verification;
