import React, { useEffect, useState } from "react";
import SearchBox from "./component/SearchBox/SearchBox";
import { AiOutlineMenu } from "react-icons/ai";
import { BiCart } from "react-icons/bi";
import { BsFillPersonFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { Modal, Image } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";

import DropDown from "./component/DropDown";
import Theme from "../../../styles/theme";
import AteejLogo from "../../../assets/images/AteejLogo.png";
import "./SearchContainer.scss";
import { useApi } from "../../../hooks/useFetch";
import { baseUrl } from "../../../utilts";

const SearchContainer = ({ img }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [
    getCategories,
    categoriesResponse,
    loadingCategories,
    CategoriesResponseStatues,
  ] = useApi();

  useEffect(() => {
    getCategories("get", `en/api/items/category/menu/2`, {}, {}, false);
    (async () => {
      setIsLoading(true);
      try {
        await axios
          .get(`${baseUrl}en/api/items/category/menu/2`)
          .then((res) => {
            const menuData = res.data;
            setData(menuData);
          })
          .catch((error) => {
            setIsLoading(true);
            const errorToSend = error?.response?.statusText.replace(
              / /g,
              "_"
            )(async () => {
              try {
                await axios({
                  method: "post",
                  url: `${baseUrl}en/api/logs/web/${errorToSend}`,
                })
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
                setIsLoading(false);
              } catch (error) {
                setIsLoading(false);
                console.log(error);
              }
            })();
          });
        await axios
          .get(`${baseUrl}en/api/items/category/menu/3`)
          .then((res) => {
            const menuData = res.data;
            setCategoryData(menuData);
          })
          .catch((error) => {
            setIsLoading(true);
            const errorToSend = error?.response?.statusText.replace(
              / /g,
              "_"
            )(async () => {
              try {
                await axios({
                  method: "post",
                  url: `${baseUrl}en/api/logs/web/${errorToSend}`,
                })
                  .then((res) => {
                    console.log(res);
                  })
                  .catch((error) => {
                    console.log(error);
                  });
                setIsLoading(false);
              } catch (error) {
                setIsLoading(false);
                console.log(error);
              }
            })();
          });
        setIsLoading(false);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  const updateModal = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    if (categoriesResponse?.length) {
      setData(categoriesResponse);
    }
  }, [categoriesResponse]);

  return (
    <div className="container-fluid">
      <div className="row mt-0 mt-lg-5 pb-3 pb-lg-5">
        <a
          href="/"
          className="col-6 col-lg-12 my-3 p-0 d-flex d-lg-none justify-content-start"
        >
          <img
            style={{ cursor: "pointer" }}
            src={img}
            alt="logo"
            width="auto"
            height="50"
          />
        </a>
        <div className="col-6 d-flex d-lg-none justify-content-around align-items-center">
          <BsFillPersonFill color={Theme.colors.black} size={22} />
          <BiCart color={Theme.colors.black} size={22} />
          <AiOutlineMenu
            onClick={() => updateModal()}
            color={Theme.colors.black}
            size={22}
          />
          <Modal
            className="menu-sidebar left"
            show={isVisible}
            onHide={updateModal}
            autoFocus
            keyboard
          >
            <Modal.Header closeButton>
              <Modal.Title>
                <Image
                  style={{ objectFit: "contain" }}
                  width="100"
                  height="100"
                  src={AteejLogo}
                />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="border-bottom">
                <p>{t("Seller Information Center")}</p>
                <p>{t("Seller Information")}</p>
              </div>
              <p>
                <a href="/login">{t("Sign in")}</a> {t("or")}{" "}
                <a href="/signup">{t("register")}</a>
              </p>
            </Modal.Body>
          </Modal>
        </div>
        <div className="col-2 col-lg-3 pl-5 rounded d-none d-lg-flex justify-content-between align-items-center">
          <a
            className="rounded-circle d-flex "
            style={{ width: 100, height: 50 }}
            onClick={() => navigate("/")}
          >
            <img
              style={{
                width: 100,
                height: 50,
                objectFit: "contain",
                cursor: "pointer",
              }}
              src={img}
              alt="logo"
              className="rounded"
            />
          </a>
          <DropDown
            title={t("Shop by category")}
            Categories={data}
            showImage={false}
          />
        </div>
        <div className="col-12 col-lg-9">
          <SearchBox data={data} />
        </div>
        <div className="col-12 d-none d-lg-flex border-bottom flex-wrap">
          {categoryData?.context?.length
            ? categoryData?.context?.map((item, index) => {
                return (
                  <div className="mx-1 py-3">
                    <DropDown
                      title={item?.title}
                      Categories={item?.childs}
                      showImage={false}
                      type="horizentalList"
                    />
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </div>
  );
};

export default SearchContainer;
