import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const withAuth = (WrappedComponent) => {
  return (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const token = localStorage.getItem("accessToken");

    useEffect(() => {
      if (!token) {
        // Pass the current path in state
        navigate("/login", { state: { from: location.pathname } });
      }
    }, [token, navigate, location]);

    return token ? <WrappedComponent {...props} /> : null;
  };
};

export default withAuth;
