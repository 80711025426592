import React,{useEffect,useState} from "react";
import { useTranslation } from 'react-i18next';
import {Button} from "react-bootstrap";
import axios from 'axios';

import Theme from "../../../../styles/theme";
import { baseUrl } from "../../../../utilts";

const EditAdressModal = ({ setSpecificAddress,addressesData})=>{
    const { t } = useTranslation();
    const [addressName, setAddressName] = useState("");
    const [addressPhone, setAddressPhone] = useState("");
    const [addressArea, setAddressArea] = useState("");
    const [addressBlock, setAddressBlock] = useState("");
    const [addressStreet, setAddressStreet] = useState("");
    const [addressbuilding, setAddressBuilding] = useState("");
    const [addressFlat, setAddressFlat] = useState("");
    const [addressPaciNumber, setAddressPaciNumber] = useState("");

    const token = localStorage.getItem("accessToken");
    useEffect(()=>{
        (async()=>{
            await axios({
                    method:'get',
                    url:`${baseUrl}en/api/addresses/${addressesData[0]?.id}`,
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    }
                }).then(res => {
                    const response = res.data.context;
                    setAddressName(response.name);
                    setAddressPhone(response.phone);
                    setAddressArea(response.area);
                    setAddressBlock(response.block);
                    setAddressStreet(response.street);
                    setAddressBuilding(response.building);
                    setAddressFlat(response.flat);
                    setAddressPaciNumber(response.paci_number);
                }).catch(error=>{
                    console.log(error);
                })
        })()
    },[])
    const editAddress =()=>{
        
        setSpecificAddress()
    }
    return(
        <div className="p-2">
            <div className="d-flex justify-content-between m-2 border-bottom">
                <h3>{t('Edit your address')}</h3>
                <p className="my-0" onClick={()=>setSpecificAddress()} style={{fontSize:30,cursor:"pointer"}}>×</p>
            </div>
            <div className="p-3">
                <div>
                    <p className="my-0" style={{fontSize:18}}>{t('address name')}</p>
                    <input
                        name="name"
                        value={addressName}
                        onChange={(e)=>setAddressName(e.target.value)}
                        className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
                        placeholder={t('name')}
                        style={{fontSize:14,color:Theme.colors.darkGrey}}
                    />
                </div>
                <div className="w-100">
                    <p className="my-0" style={{fontSize:18}}>{t('phone number')}</p>
                    <input
                        name="phone"
                        value={addressPhone}
                        onChange={(e)=>setAddressPhone(e.target.value)}
                        className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
                        placeholder={t('phone')}
                        style={{fontSize:14,color:Theme.colors.darkGrey}}
                    />
                </div>
                <div className="w-100">
                    <p className="my-0" style={{fontSize:18}}>{t('area')}</p>
                    <input
                        name="area"
                        value={addressArea}
                        onChange={(e)=>setAddressArea(e.target.value)}
                        className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
                        placeholder={t('area')}
                        style={{fontSize:14,color:Theme.colors.darkGrey}}
                    />
                </div>
                <div className="w-100">
                    <p className="my-0" style={{fontSize:18}}>{t('block')}</p>
                    <input
                        name="block"
                        value={addressBlock}
                        onChange={(e)=>setAddressBlock(e.target.value)}
                        className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
                        placeholder={t('block')}
                        style={{fontSize:14,color:Theme.colors.darkGrey}}
                    />
                </div>
                <div className="w-100">
                    <p className="my-0" style={{fontSize:18}}>{t('street')}</p>
                    <input
                        name="street"
                        value={addressStreet}
                        onChange={(e)=>setAddressStreet(e.target.value)}
                        className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
                        placeholder={t('street')}
                        style={{fontSize:14,color:Theme.colors.darkGrey}}
                    />
                </div>
                <div className="w-100">
                    <p className="my-0" style={{fontSize:18}}>{t('building')}</p>
                    <input
                        name="building"
                        value={addressbuilding}
                        onChange={(e)=>setAddressBuilding(e.target.value)}
                        className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
                        placeholder={t('building')}
                        style={{fontSize:14,color:Theme.colors.darkGrey}}
                    />
                </div>
                <div className="w-100">
                    <p className="my-0" style={{fontSize:18}}>{t('flat')}</p>
                    <input
                        name="flat"
                        value={addressFlat}
                        onChange={(e)=>setAddressFlat(e.target.value)}
                        className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
                        placeholder={t('flat')}
                        style={{fontSize:14,color:Theme.colors.darkGrey}}
                    />
                </div>
                <div className="w-100">
                    <p className="my-0" style={{fontSize:18}}>{t('paciNumber')}</p>
                    <input
                        name="paciNumber"
                        value={addressPaciNumber}
                        onChange={(e)=>setAddressPaciNumber(e.target.value)}
                        className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
                        placeholder={t('paciNumber')}
                        style={{fontSize:14,color:Theme.colors.darkGrey}}
                    />
                </div>
                <div className="d-flex justify-content-end">
                    <Button onClick={()=>editAddress()} variant="primary rounded-0">{t('Save Changes')}</Button>
                </div>
            </div>
        </div>
    )
}

export default EditAdressModal;