import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { Button, Accordion, Dropdown, FormControl } from "react-bootstrap";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import AddressesList from "../AddressesList";
import Theme from "../../../../styles/theme";
import { baseUrl } from "../../../../utilts";

const AdressesModal = ({
  setAdressesModal,
  data,
  setRefreshPage,
  setItemAdded,
  setErrorAddItems,
}) => {
  const { t } = useTranslation();
  var bodyFormData = new FormData();
  const { handleSubmit, register } = useForm();
  const [newAddressName, setNewAddressName] = useState("");
  const [newAddressArea, setNewAddressArea] = useState("");
  const [newAddressBlock, setNewAddressBlock] = useState("");
  const [newAddressStreet, setNewAddressStreet] = useState("");
  const [newAddressBuilding, setNewAddressBuilding] = useState("");
  const [newAddressFlat, setNewAddressFlat] = useState("");
  const [newAddressPaciNumber, setNewAddressPaciNumber] = useState("");
  const [countriesData, setCountriesData] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [countrySelected, setCountrySelected] = useState({
    name: t("all"),
    id: 0,
  });
  useEffect(() => {
    (async () => {
      try {
        await axios({
          method: "get",
          url: `${baseUrl}en/api/countries/`,
        })
          .then((res) => {
            setCountriesData(res.data?.context);
          })
          .catch((error) => {
            const errorToSend = error?.response?.statusText?.replace(
              / /g,
              "_"
            )(async () => {
              await axios({
                method: "post",
                url: `${baseUrl}en/api/logs/web/${errorToSend}`,
              })
                .then((res) => {
                  console.log(res);
                })
                .catch((error) => {
                  console.log(error);
                });
            })();
          });
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);
  const token = localStorage.getItem("accessToken");
  const onSubmit = async (values) => {
    bodyFormData.append("name", values?.name);
    bodyFormData.append("country", countrySelected?.id);
    bodyFormData.append("phone", phoneNumber);
    bodyFormData.append("area", values?.area);
    bodyFormData.append("block", values?.block);
    bodyFormData.append("street", values?.street);
    bodyFormData.append("building", values?.building);
    bodyFormData.append("flat", values?.flat);
    bodyFormData.append("paci_number", values?.paci_number);
    // SaveNewAddress()
    try {
      await axios({
        method: "post",
        url: `${baseUrl}en/api/addresses/`,
        data: bodyFormData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(() => setItemAdded())
        .catch((error) => {
          setErrorAddItems();
          if (error.response) {
            console.log("error?.response", error?.response);
          } else if (error.request) {
            console.log("error?.message", error?.message);
          } else if (error.message) {
            console.log("error?.request", error?.request);
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <p
      className="d-flex justify-content-between align-items-center border-bottom"
      style={{ cursor: "pointer", color: Theme.colors.darkGrey }}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
      <span className="ml-2" style={{ fontSize: 12 }}>
        &#x25bc;
      </span>
    </p>
  ));

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");

      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            autoFocus
            className="mx-3 my-2 w-auto"
            placeholder={t("Type to filter Countries")}
            onChange={(e) => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) =>
                !value || child.props.children.toLowerCase().startsWith(value)
            )}
          </ul>
        </div>
      );
    }
  );
  return (
    <>
      <div className="p-2">
        <div className="d-flex justify-content-between m-2 border-bottom">
          <h3>{t("Your addresses")}</h3>
          <p
            onClick={() => setAdressesModal()}
            className="my-0"
            style={{ fontSize: 30, cursor: "pointer" }}
          >
            ×
          </p>
        </div>
        {data.map((item, index) => {
          return (
            <AddressesList
              key={item.id}
              data={item}
              index={index}
              refreshPage={() => setRefreshPage()}
            />
          );
        })}
      </div>
      <Accordion className="w-100" defaultActiveKey="0">
        <Accordion.Toggle
          as={Button}
          variant="link text-left w-100"
          eventKey="1"
        >
          <div className="d-flex justify-content-end mx-auto w-100">
            <Button variant="primary rounded-0 w-100">
              {t("Add new address")}
            </Button>
          </div>
        </Accordion.Toggle>
        <form name="form" className="col-12" onSubmit={handleSubmit(onSubmit)}>
          <div className="p-3">
            <Accordion.Collapse eventKey="1">
              <div className="w-100">
                <p className="my-0" style={{ fontSize: 18 }}>
                  {t("name")}
                </p>
                <input
                  name="name"
                  ref={register({
                    required: "Required",
                  })}
                  value={newAddressName}
                  onChange={(e) => setNewAddressName(e.target.value)}
                  className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
                  placeholder={t("name")}
                  style={{ fontSize: 14, color: Theme.colors.darkGrey }}
                />
              </div>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="1">
              <div className="w-100">
                <p className="my-0" style={{ fontSize: 18 }}>
                  {t("Select country")}
                </p>
                <Dropdown className="w-100 my-3">
                  <Dropdown.Toggle
                    as={CustomToggle}
                    id="dropdown-custom-components"
                  >
                    {countrySelected?.name}
                  </Dropdown.Toggle>
                  <Dropdown.Menu as={CustomMenu}>
                    {countriesData?.map((item, index) => {
                      const changeCountry = () => {
                        setCountrySelected(item);
                      };
                      return (
                        <Dropdown.Item
                          onClick={() => changeCountry()}
                          key={item?.id}
                          eventKey={1}
                        >
                          {item?.name}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="1">
              <div className="w-100 mb-3">
                <p className="mb-2">{t("Phone Number")}</p>
                <PhoneInput
                  ref={register}
                  buttonClass="rounded-0"
                  inputClass="w-100 rounded-0 border-top-0 border-right-0 border-left-0 border-bottom"
                  name="phoneNumber"
                  type="text"
                  country={"kw"}
                  enableAreaCodes={true}
                  areaCodes={{ us: ["332"] }}
                  inputProps={{
                    name: "phone",
                    country: "us",
                    required: true,
                    autoFocus: true,
                  }}
                  value={phoneNumber}
                  onChange={(value) => setPhoneNumber(value)}
                  required
                  inputStyle={{ backgroundColor: Theme.colors.grey }}
                />
              </div>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="1">
              <div className="w-100">
                <p className="my-0" style={{ fontSize: 18 }}>
                  {t("area")}
                </p>
                <input
                  name="area"
                  ref={register({
                    required: "Required",
                  })}
                  value={newAddressArea}
                  onChange={(e) => setNewAddressArea(e.target.value)}
                  className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
                  placeholder={t("area")}
                  style={{ fontSize: 14, color: Theme.colors.darkGrey }}
                />
              </div>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="1">
              <div className="w-100">
                <p className="my-0" style={{ fontSize: 18 }}>
                  {t("block")}
                </p>
                <input
                  name="block"
                  ref={register({
                    required: "Required",
                  })}
                  type="number"
                  value={newAddressBlock}
                  onChange={(e) => setNewAddressBlock(e.target.value)}
                  className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
                  placeholder={t("block")}
                  style={{ fontSize: 14, color: Theme.colors.darkGrey }}
                />
              </div>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="1">
              <div className="w-100">
                <p className="my-0" style={{ fontSize: 18 }}>
                  {t("street")}
                </p>
                <input
                  name="street"
                  ref={register({
                    required: "Required",
                  })}
                  value={newAddressStreet}
                  onChange={(e) => setNewAddressStreet(e.target.value)}
                  className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
                  placeholder={t("street")}
                  style={{ fontSize: 14, color: Theme.colors.darkGrey }}
                />
              </div>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="1">
              <div className="w-100">
                <p className="my-0" style={{ fontSize: 18 }}>
                  {t("building")}
                </p>
                <input
                  name="building"
                  ref={register({
                    required: "Required",
                  })}
                  type="number"
                  value={newAddressBuilding}
                  onChange={(e) => setNewAddressBuilding(e.target.value)}
                  className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
                  placeholder={t("building")}
                  style={{ fontSize: 14, color: Theme.colors.darkGrey }}
                />
              </div>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="1">
              <div className="w-100">
                <p className="my-0" style={{ fontSize: 18 }}>
                  {t("flat")}
                </p>
                <input
                  name="flat"
                  ref={register({
                    required: "Required",
                  })}
                  type="number"
                  value={newAddressFlat}
                  onChange={(e) => setNewAddressFlat(e.target.value)}
                  className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
                  placeholder={t("flat")}
                  style={{ fontSize: 14, color: Theme.colors.darkGrey }}
                />
              </div>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="1">
              <div className="w-100">
                <p className="my-0" style={{ fontSize: 18 }}>
                  {t("paci number")}
                </p>
                <input
                  name="paci_number"
                  ref={register({
                    required: "Required",
                  })}
                  type="number"
                  value={newAddressPaciNumber}
                  onChange={(e) => setNewAddressPaciNumber(e.target.value)}
                  className="border-top-0 border-right-0 border-left-0 border-bottom my-3 w-100"
                  placeholder={t("paci number")}
                  style={{ fontSize: 14, color: Theme.colors.darkGrey }}
                />
              </div>
            </Accordion.Collapse>
            <Accordion.Collapse eventKey="1">
              <div className="w-100">
                <Button type="submit" variant="primary rounded-0 w-100">
                  {t("Save")}
                </Button>
              </div>
            </Accordion.Collapse>
          </div>
        </form>
      </Accordion>
    </>
  );
};

export default AdressesModal;
